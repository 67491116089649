import { graphql } from 'gatsby'
import get from 'lodash/get'
import PropTypes from 'prop-types'
import { useMemo } from 'react'
import { InView } from 'react-intersection-observer'
import tw, { css } from 'twin.macro'
import Typewriter from 'typewriter-effect'
import AnimatedHero from '../../components/animated-hero'
import ArticleCarousel from '../../components/article-carousel'
import Button from '../../components/button'
import Heading from '../../components/heading'
import Layout from '../../components/layout'
import LogoFade from '../../components/logo-fade'
import StatisticsScroller from '../../components/statistics-scroller'
import Testimonials from '../../components/testimonials'
import Text from '../../components/text'
import ReactPlayer from 'react-player/youtube'
import { global } from '../../styles/global'
import { flatten } from '../../utils/data'
import { ReactComponent as Play } from '../../images/icon-play-sm-solid.svg'

import loadable from '@loadable/component'
//import Animation from '../../components/animation'
const Animation = loadable(() => import('../../components/animation'))

const IndexPage = ({ data: { index } }) => {
  const page = useMemo(
    () =>
      flatten(index || {}, [
        'hero',
        'about',
        'statisticsCta',
        'ecosystemCta',
        'partnersCta',
        'logosHeader',
        'newsHeader',
      ]),
    [index]
  )

  return (
    <Layout
      pageTags={page.seoMetaTags}
      noIndex={page.noIndex}
      headerTransition={true}
      headerTransitionColor="white"
    >
      <section id="hero" css={tw`relative h-78vh lg:(h-screen)`}>
        <AnimatedHero
          animation={get(page, 'hero.animation')}
          animationMobile={get(page, 'hero.animationMobile')}
          title={get(page, 'hero.titleNode')}
          description={get(page, 'heroDescription')}
          callToAction={get(page, 'hero.callToAction')}
          extended
          isFull={false}
        />
      </section>
      <section id="statistics" css={tw`relative bg-accent-50 lg:min-h-screen`}>
        <div css={[global`layout.container`, global`layout.grid`, tw`h-full`]}>
          <div css={tw`col-span-4 py-20 md:col-span-8 lg:(col-span-5 py-30) xl:col-span-6`}>
            <div css={tw`sticky flex flex-col items-start top-32`}>
              <Heading
                content={get(page, 'statisticsCta.titleNode')}
                headingType="h2"
                style={[global`base.bold-to-medium`]}
              />
              <Text content={get(page, 'statisticsCta.descriptionNode')} style={tw`mt-6`} />
              <Button
                label={get(page, 'statisticsCta.callToAction.label')}
                link={get(page, 'statisticsCta.callToAction.link')}
                disabled={get(page, 'statisticsCta.callToAction.disabled')}
                type="primary"
                size="sm"
                theme="navy-cyan"
                style={tw`mt-8 lg:mt-10`}
              />
            </div>
          </div>
          <div
            css={tw`col-span-4 md:col-span-8 lg:(col-start-7 col-end-13 -mr-container-lg)
            xl:(col-start-9 col-end-17 -mr-container-xl)
            xxl:-mr-container-xxl`}
          >
            <StatisticsScroller
              statistics={get(page, 'statistics')}
              style={tw`pb-20 lg:(pt-30 pb-30)`}
            />
          </div>
        </div>
      </section>
      <section id="logos" css={tw`py-8 bg-white lg:(pt-24 pb-20)`}>
        <div css={[global`layout.container`, tw`overflow-hidden lg:w-full`]}>
          <Heading
            content={get(page, 'logosHeader.titleNode')}
            headingType="h2"
            style={tw`text-center text-primary-500 mb-14 lg:mb-12`}
          />
        </div>
        <LogoFade
          logos={get(page, 'logos')}
          style={[tw`mx-container-mobile`, global`layout.desktop-container`]}
        />
      </section>
      <section id="ecosystem" css={tw`relative flex flex-col items-center pt-24 lg:pt-0`}>
        <div
          css={[
            global`layout.mobile-container`,
            tw`relative flex flex-col items-center text-center xl:w-1/2`,
          ]}
        >
          <Heading content={get(page, 'ecosystemCta.titleNode')} headingType="h3" />
          <Text content={get(page, 'ecosystemCta.descriptionNode')} style={tw`mt-8`} />
          <Button
            type="primary"
            size="sm"
            theme="transparent-transparent"
            label={get(page, 'ecosystemCta.callToAction.label')}
            link={get(page, 'ecosystemCta.callToAction.link')}
            style={tw`mt-8 border border-primary-500 z-10 lg:mt-12`}
          />
        </div>
      </section>
      <section
        id="ecosystem-banner"
        css={css`
          ${tw`relative w-full h-150 lg:h-200`}
          background: linear-gradient(180deg, #ffffff 50%, #170f4f 50%);
        `}
      >
        <InView triggerOnce={true}>
          {({ inView, ref }) => (
            <div css={tw`absolute inset-x-0 -inset-y-20`} ref={ref}>
              <Animation
                url={get(page, 'ecosystemImage.url')}
                style={tw`h-full`}
                play={inView}
                options={{
                  loop: false,
                  autoplay: false,
                  rendererSettings: {
                    preserveAspectRatio: 'xMidYMin slice',
                  },
                }}
              />
            </div>
          )}
        </InView>
      </section>
      <section id="about" css={tw`relative bg-primary-500`}>
        <div css={[global`layout.container`, global`layout.grid`, tw`relative`]}>
          <div css={tw`col-span-4 md:col-span-8 lg:(col-start-1 col-end-10) xl:col-end-11`}>
            <Heading
              headingType="h3"
              content={get(page, 'about.titleNode')}
              style={css`
                ${tw`text-white`}
                strong {
                  ${tw`font-medium`}
                }
              `}
            />
          </div>
          <div
            css={tw`col-span-4 mt-12 flex flex-col items-start
            md:col-span-8 lg:(col-start-1 col-end-6 mt-8 mb-12) xl:col-end-7`}
          >
            <Text
              content={get(page, 'about.descriptionNode')}
              style={css`
                ${tw`text-white`}
                p {
                  ${tw`mb-8 last-of-type:mb-0`}
                }
              `}
            />
            {page?.about?.callToAction && (
              <Button
                type="primary"
                theme="cyan-yellow"
                size="sm"
                label={get(page, 'about.callToAction.label')}
                link={get(page, 'about.callToAction.link')}
                style={tw`mt-10`}
              />
            )}
          </div>
          <div
            css={tw`col-span-4 -mr-container-mobile mt-14 md:col-span-8
            lg:(col-start-6 col-end-13 mt-18 -mr-container-lg)
            xl:(col-start-8 col-end-17 -mr-container-xl) xxl:-mr-container-xxl`}
          >
            <div css={tw`pb-ratio-16/10 relative z-10 -mb-2/5-screen lg:-mb-28`}>
              <ReactPlayer
                url={get(page, 'about.embedVideo.url')}
                light={get(page, 'about.poster.url')}
                playIcon={
                  <button>
                    <Play />
                  </button>
                }
                playing
                width="100%"
                height="100%"
                loop
                controls
                muted
                playsinline
                style={tw`absolute`}
              />
            </div>
          </div>
        </div>
      </section>
      <section id="partners" css={tw`relative bg-primary-50 pt-2/5-screen pb-20 lg:(pt-28 pb-24)`}>
        <div css={[global`layout.container`, global`layout.grid`, tw`items-center mt-20 lg:mt-0`]}>
          <div
            css={tw`col-span-4 md:col-span-8 flex flex-col items-start lg:(col-span-5) xl:col-span-6`}
          >
            <Heading
              content={get(page, 'partnersCta.titleNode')}
              headingType="h3"
              style={global`base.bold-to-medium`}
            />
            <Text content={get(page, 'partnersCta.descriptionNode')} style={tw`mt-6`} />
            <Button
              type="primary"
              size="sm"
              label={get(page, 'partnersCta.callToAction.label')}
              link={get(page, 'partnersCta.callToAction.link')}
              theme="navy-cyan"
              style={tw`mt-10`}
            />
          </div>
          <div
            css={tw`flex flex-col col-span-4 mt-16 md:col-span-8 lg:(mt-0 col-start-7 col-end-13) xl:(col-start-9 col-end-17)`}
          >
            <Heading
              content={get(page, 'partnersCaptionPrefix')}
              headingType="h1"
              style={tw`font-light text-primary-200 lg:(text-5xl tracking-display-lg leading-overline) xl:(text-7xl)`}
            />
            <div
              css={[
                global`typography.h1`,
                tw`font-light text-primary-500 lg:(leading-none text-5xl tracking-display-lg) xl:(text-7xl)`,
                global`base.bold-to-normal`,
              ]}
            >
              <Typewriter
                options={{
                  loop: true,
                }}
                onInit={(typewriter) => {
                  const strings = get(page, 'partnersCaptionNode')
                    .childMarkdownRemark.html.replace(/(<p>)|(<\/p>)/g, '')
                    .split('<br>\n')
                  for (let i = 0; i < strings.length; i++) {
                    typewriter.typeString(strings[i]).pauseFor(2000).deleteAll()
                  }
                  typewriter.start()
                }}
              />
            </div>
          </div>
        </div>
      </section>
      <section
        id="testimonials"
        css={tw`relative bg-secondary-500 overflow-hidden pt-12 pb-14 lg:(py-24)`}
      >
        <Testimonials testimonials={get(page, 'testimonials')} />
      </section>
      {page.newsHeader !== undefined && (
        <section id="news" css={tw`relative py-24 overflow-hidden lg:(pt-24 pb-24)`}>
          <div
            css={[global`layout.container`, tw`lg:(flex flex-row items-center justify-between)`]}
          >
            <Heading
              content={get(page, 'newsHeader.titleNode')}
              headingType="h3"
              style={tw`font-bold text-primary-500`}
            />
            <Button
              type="primary"
              size="sm"
              style={tw`hidden opacity-50 lg:flex`}
              theme="transparent-transparent"
              label={get(page, 'newsLink.label')}
              link={get(page, 'newsLink.link')}
            />
          </div>
          <ArticleCarousel cards={get(page, 'news')} style={tw`mt-16 mb-20 lg:(mt-20 mb-0)`} />
          <div css={[global`layout.container`]}>
            <Button
              type="primary"
              size="sm"
              style={css`
                ${tw`opacity-50 lg:hidden`}
                span {
                  ${tw`ml-0`}
                }
              `}
              theme="transparent-transparent"
              label={get(page, 'newsLink.label')}
              link={get(page, 'newsLink.link')}
            />
          </div>
        </section>
      )}
    </Layout>
  )
}

IndexPage.propTypes = {
  data: PropTypes.shape({
    index: PropTypes.object.isRequired,
  }),
}

export default IndexPage

export const query = graphql`
  query IndexQuery($locale: String!) {
    index: datoCmsParentHome(locale: { eq: $locale }) {
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      noIndex
      hero {
        animation {
          format
          url
        }
        animationMobile {
          format
          url
        }
        titleNode {
          childMarkdownRemark {
            html
          }
        }
        descriptionNode {
          childMarkdownRemark {
            html
          }
        }
      }
      heroDescription {
        textNode {
          childMarkdownRemark {
            html
          }
        }
      }
      statisticsCta {
        titleNode {
          childMarkdownRemark {
            html
          }
        }
        descriptionNode {
          childMarkdownRemark {
            html
          }
        }
        callToAction {
          label
          link
          disabled
        }
      }
      statistics {
        labelNode {
          childMarkdownRemark {
            html
          }
        }
        value
      }
      logosHeader {
        titleNode {
          childMarkdownRemark {
            html
          }
        }
      }
      logos {
        format
        url
      }
      about {
        titleNode {
          childMarkdownRemark {
            html
          }
        }
        descriptionNode {
          childMarkdownRemark {
            html
          }
        }
        callToAction {
          label
          link
          disabled
        }
        video {
          video {
            thumbnailUrl(format: gif)
            streamingUrl
            mp4Url
          }
        }
        poster {
          format
          url
        }
        embedVideo {
          thumbnailUrl
          url
        }
      }
      ecosystemCta {
        titleNode {
          childMarkdownRemark {
            html
          }
        }
        descriptionNode {
          childMarkdownRemark {
            html
          }
        }
        callToAction {
          label
          link
          disabled
        }
      }
      ecosystemImage {
        format
        url
      }
      partnersCta {
        titleNode {
          childMarkdownRemark {
            html
          }
        }
        descriptionNode {
          childMarkdownRemark {
            html
          }
        }
        callToAction {
          label
          link
          disabled
        }
      }
      partnersCaptionPrefix
      partnersCaptionNode {
        childMarkdownRemark {
          html
        }
      }
      testimonials {
        image {
          format
          url
        }
        titleNode {
          childMarkdownRemark {
            html
          }
        }
        subtitleNode {
          childMarkdownRemark {
            html
          }
        }
        descriptionNode {
          childMarkdownRemark {
            html
          }
        }
      }
      newsHeader {
        titleNode {
          childMarkdownRemark {
            html
          }
        }
      }
      news {
        slug
        headline
        date
        contentNode {
          childMarkdownRemark {
            excerpt
          }
        }
      }
      newsLink {
        label
        link
        disabled
      }
    }
  }
`
